<template>
    <div class="h-30 w-full rounded bg-white">
        <div class="flex flex-col px-4 py-4">
            <div class="flex pb-4 text-sm">
                {{ $t("helpers.inputs.label.classical_view") }}
            </div>
            <smu-button class="flex" @click="navigateToOldBackend">
                <div class="flex">
                    <span class="pt-0.5">
                        <smu-icon type="duotone" icon="shuffle" />
                    </span>
                    <div class="text-wrap py-1 pl-1 text-xs">
                        {{ $t("helpers.buttons.switch_to_classical_view") }}
                    </div>
                </div>
            </smu-button>
        </div>
    </div>
</template>

<script setup lang="ts">
const { data: user } = storeToRefs(useUserStore());

const { slug } = useRoute().params;

const config = useRuntimeConfig();

const url = computed(() => {
    return `https://${slug}.${config.public.productionBaseUrl}`;
});

function navigateToOldBackend() {
    if (!user.value) return;

    return navigateTo(url.value, {
        external: true,
    });
}
</script>
