<template>
    <smu-header-items-transition>
        <div>
            <smu-main-menu />
            <div v-for="section in filteredSections" :key="section.name">
                <smu-menu-section
                    :name="section.name"
                    :has-active-links="sectionHasActiveLinks(section.links)"
                >
                    <div v-for="item in section.links" :key="item.name">
                        <nuxt-link
                            v-if="linkIsAuthorized(item)"
                            :to="item.url"
                            class="flex h-full w-full active:text-primary"
                            active-class="text-primary"
                        >
                            <smu-menu-section-entry
                                :is-beta="item.beta"
                                :is-dev="item.dev"
                                :is-active="isActive(item.url)"
                                class="w-full"
                            >
                                <div class="flex w-full">
                                    <span class="w-6">
                                        <smu-icon v-bind="item.icon" />
                                    </span>
                                    <p>
                                        {{ item.name }}
                                    </p>
                                </div>
                            </smu-menu-section-entry>
                        </nuxt-link>
                    </div>
                </smu-menu-section>
            </div>
            <div
                v-if="
                    isAuthorised({ permission: 'manage go-to-legacy-backend' })
                "
            >
                <menu-redirect />
            </div>
        </div>
    </smu-header-items-transition>
</template>

<script setup lang="ts">
import type { SmuIconProps } from "@smakecloud/smake-ui";
import { useSlugStore } from "~/stores/useSlugStore";

type Link = {
    name: string;
    icon: SmuIconProps;
    url: string;
    dev?: boolean;
    beta?: boolean;
    permission?: string;
};

type Section = {
    name: string;
    links: Link[];
};

const { t } = useI18n();
const { isAuthorised } = useAuthorisationChecker();
const router = useRouter();
const route = useRoute();
const { slug } = useSlugStore();

function isActive(url: string) {
    const regex = new RegExp(url + ".*$");
    return regex.test(route.path);
}

function sectionHasActiveLinks(links: Link[]) {
    for (const link of links) {
        if (isActive(link.url)) {
            return true;
        }
    }

    return false;
}

const linkIsAuthorized = (link: Link) => {
    return isAuthorised(link);
};

const sections = ref<Section[]>([
    {
        name: "",
        links: [
            {
                name: t("shop/backend.dashboard.menu.title"),
                icon: {
                    type: "light",
                    icon: "tachometer-alt",
                },
                url: `/${slug}/dashboard`,
            },
            {
                name: t("shop/backend.inventory.menu.title"),
                icon: {
                    type: "light",
                    icon: "inventory",
                },
                url: `/${slug}/inventory`,
            },
        ],
    },
    {
        name: t("eloquent.attributes.tenant.settings"),
        links: [
            {
                name: t("eloquent.attributes.production_method.title"),
                icon: {
                    type: "light",
                    icon: "gem",
                },
                url: `/${slug}/production-methods`,
            },
        ],
    },
]);

const filteredSections = computed(() =>
    sections.value.filter(
        (section) =>
            section.links.filter((link) => {
                const route = router.resolve(link.url);

                return isAuthorised(
                    route.matched.length === 0 ? link : route.meta,
                );
            }).length > 0,
    ),
);

const productionProductionStore = useProductionProductionStore();

const { productionProductionMethods } = storeToRefs(productionProductionStore);

const productionMethods = computed<Link[]>(
    () =>
        productionProductionMethods.value?.data
            .filter((method) => method.has_presorting)
            .map((method) => ({
                name: method.production_method.display_name,
                icon: {
                    type:
                        method.production_method_handle === "dtf"
                            ? "kit"
                            : "light",
                    icon:
                        method.production_method_handle === "dtf"
                            ? method.production_method_handle
                            : "container-storage",
                },
                url: `/${slug}/presorting/${method.id}`,
                permission: "manage presorting",
            })) ?? [],
);

watchEffect(() => {
    if (productionMethods.value) {
        sections.value.push({
            name: t("helpers.common.presorting"),
            links: productionMethods.value,
        });
    }
});
</script>
